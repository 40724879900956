import React from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import img from "../../images/image 144 (1).png";
import "./payment.css";
import { useNavigate } from "react-router-dom";

const PaymentUnsuccessful = () => {
  const navigate = useNavigate();
  return (
    <div className="payment-status-page">
      <Header />
      <div className="payment-status-container">
        <h2>Payment Failed!</h2>
        <img src={img} />
        <p style={{ padding: "0", paddingBottom: "10px", margin: "0" }}>
          Your payment is unsuccessful...
        </p>
        <p style={{ padding: "0", paddingBottom: "10px", margin: "0" }}>
          The payment was unsuccessful due to an abnormality. Please try again
          or use another payment method..
        </p>
        <button type="submit" onClick={() => navigate("/checkout")}>
          Try Again
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentUnsuccessful;
