import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SignUp.css";
import { FaRegEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/header";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../constant";

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [errors, setErrors] = useState({});

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const errors = {};

    // Check if name is empty
    if (!name.trim()) {
      errors.name = "Name is required";
    }

    // Check if phone number is empty or not 10 digits
    if (!phoneNo.trim() || phoneNo.length !== 10 || !/^\d{10}$/.test(phoneNo)) {
      errors.phoneNo = "Please enter a valid 10-digit phone number";
    }

    // Check if email is empty or not valid
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid email address";
    }

    // Check if password is empty or less than 6 characters
    if (!password.trim() || password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    // Check if terms are not checked
    if (!termsChecked) {
      errors.termsChecked = "Please accept the Terms and Conditions";
    }

    return errors;
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      // Adding user with email
      const emailResponse = await axios.post(
        `${BASE_URL}/users/addNewUserEmail?emailId=${email}&password=${password}&name=${name}&phoneNo=${phoneNo}`
      );
      console.log(emailResponse);

      const { errorMessage } = emailResponse;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.success("User added successfully!");
        navigate("/login");
      }
    } catch (error) {
      console.error(error.response);
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="signup-page">
      <Header />
      <div className="signup-main-container">
        <h2>
          Elevate Your Atmosphere:
          <br /> Register with Divine Depot
        </h2>
        <div className="signup-form-container">
          <form className="signup-form" onSubmit={handleAddUser}>
            <h3>New to Divine Depot ? Sign Up!</h3>
            <p>
              Let’s get you all set up to you can start creating your Account
            </p>
            <div className="inputs">
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <span className="error">{errors.name}</span>}
              <input
                type="text"
                placeholder="Phone No."
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
              {errors.phoneNo && (
                <span className="error">{errors.phoneNo}</span>
              )}
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />

                <button type="button" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <FaEyeSlash className="field-icon" size={25} />
                  ) : (
                    <FaRegEye className="field-icon" size={25} />
                  )}
                </button>
              </div>
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}

              <div id="checkbox-container">
                <input
                  type="checkbox"
                  id="terms-checkbox"
                  onChange={(e) => setTermsChecked(e.target.checked)}
                  required
                />
                <label htmlFor="terms-checkbox">
                  I accept the Terms and Conditions
                </label>
              </div>
              <button type="submit" className="signup-btn">
                Sign Up
              </button>
              <div className="divider">
                <span>or</span>
              </div>
            </div>
            <div className="login-link">
              <span>
                Already have an account? <Link to={"/login"}>login</Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
