import React from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import border from "../../images/border.png";
import "./AboutPage.css";
import img1 from "../../images/black.png";
import img2 from "../../images/values.png";
import img3 from "../../images/family.png";
import customer from "../../images/customer.png";
import pattern from "../../images/Clip path group.png";
import background from "../../images/background.png";

const AboutPage = () => {
  return (
    <div style={{ margin: "0", padding: "0" }}>
      <Header />
      <div class="img-container">
        <div class="img-background"></div>
        <div class="img-overlay"></div>
        <div className="about-intro">
          <div className="heading">
            <h1>About Divine Depot</h1>
            <img src={border} alt="img" id="border" loading="lazy" />
          </div>
          <p className="about-text">
            At Divine Depot, spirituality isn't just a business it's a family
            tradition passed down through generations. What started as a humble
            endeavour rooted in our deep reverence for the sacred has blossomed
            into a cherished sanctuary for seekers of spiritual wisdom and
            enlightenment.
          </p>
        </div>
      </div>
      <div className="vision">
        <img src={pattern} className="pattern-img" loading="lazy" />
        <img src={background} className="mandala" loading="lazy" />
        <img src={img1} className="circle-img" loading="lazy" />
        <div className="text">
          <div className="headings">
            <h1>Our Vision</h1>
            <img src={border} width={"350px"} alt="img" id="border" />
          </div>
          <p>
            At Divine Depot, our values are deeply intertwined with our family
            heritage. Purity, authenticity, and tradition guide everything we
            do, ensuring that each product we offer carries the essence of
            sacredness and reverence. Our commitment to integrity and respect is
            reflected in every interaction with our customers and partners.
          </p>
        </div>
      </div>
      <div className="values">
        <img src={pattern} className="pattern-img" loading="lazy" />
        <img src={background} className="mandala" loading="lazy" />
        <img src={img2} className="circle-img" loading="lazy" />
        <div className="text">
          <div className="headings">
            <h1>Core Values</h1>
            <img src={border} width={"350px"} alt="img" id="border" />
          </div>
          <p>
            At Divine Depot, our values are deeply intertwined with our family
            heritage. Purity, authenticity, and tradition guide everything we
            do, ensuring that each product we offer carries the essence of
            sacredness and reverence. Our commitment to integrity and respect is
            reflected in every interaction with our customers and partners.
          </p>
        </div>
      </div>
      <div className="family">
        <img src={pattern} className="pattern-img" loading="lazy" />
        <img src={background} className="mandala" loading="lazy" />
        <img src={img3} className="circle-img" loading="lazy" />
        <div className="text">
          <div className="headings">
            <h1>Meet Our Family</h1>
            <img src={border} width={"350px"} alt="img" id="border" />
          </div>
          <p>
            As a family-owned business, every member of the Divine Depot team
            plays a vital role in our mission. From sourcing the finest
            ingredients to crafting each product with care and intention, we
            pour our hearts and souls into every aspect of our business. Meet
            the faces behind Divine Depot and discover the passion and
            dedication that drive us forward.
          </p>
        </div>
      </div>
      <div className="customer-stories">
        <div className="headings">
          <h1>Customer Stories</h1>
          <img src={border} width={"400px"} alt="img" />
        </div>

        <div>
          <div className="customer-slider">
            <input type="radio" name="testimonial" id="t-1" />
            <input type="radio" name="testimonial" id="t-2" />
            <input type="radio" name="testimonial" id="t-3" defaultChecked />
            <input type="radio" name="testimonial" id="t-4" />
            <input type="radio" name="testimonial" id="t-5" />

            <div className="customer-testimonials">
              <label className="item" htmlFor="t-1">
                <div className="story">
                  <div className="customer-name">
                    <img src={customer} width={"70px"} loading="lazy" />
                    <h4>Raj</h4>
                  </div>
                  <p>
                    LMeet the passionate individuals who bring Divine Depot's
                    vision to life. Our team consists of dedicated professionals
                    with diverse backgrounds and expertise,{" "}
                  </p>
                </div>
              </label>
              <label className="item" htmlFor="t-2">
                <div className="story">
                  <div className="customer-name">
                    <img src={customer} width={"70px"} loading="lazy" />
                    <h4>Raj</h4>
                  </div>
                  <p>
                    LMeet the passionate individuals who bring Divine Depot's
                    vision to life. Our team consists of dedicated professionals
                    with diverse backgrounds and expertise,{" "}
                  </p>
                </div>
              </label>
              <label className="item" htmlFor="t-3">
                <div className="story">
                  <div className="customer-name">
                    <img src={customer} width={"70px"} loading="lazy" />
                    <h4>Raj</h4>
                  </div>
                  <p>
                    LMeet the passionate individuals who bring Divine Depot's
                    vision to life. Our team consists of dedicated professionals
                    with diverse backgrounds and expertise,{" "}
                  </p>
                </div>
              </label>
              <label className="item" htmlFor="t-4">
                <div className="story">
                  <div className="customer-name">
                    <img src={customer} width={"70px"} loading="lazy" />
                    <h4>Raj</h4>
                  </div>
                  <p>
                    LMeet the passionate individuals who bring Divine Depot's
                    vision to life. Our team consists of dedicated professionals
                    with diverse backgrounds and expertise,{" "}
                  </p>
                </div>
              </label>
              <label className="item" htmlFor="t-5">
                <div className="story">
                  <div className="customer-name">
                    <img src={customer} width={"70px"} loading="lazy" />
                    <h4>Raj</h4>
                  </div>
                  <p>
                    LMeet the passionate individuals who bring Divine Depot's
                    vision to life. Our team consists of dedicated professionals
                    with diverse backgrounds and expertise,{" "}
                  </p>
                </div>
              </label>
            </div>
            <div class="dots">
              <label for="t-1"></label>
              <label for="t-2"></label>
              <label for="t-3"></label>
              <label for="t-4"></label>
              <label for="t-5"></label>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage;
