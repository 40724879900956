import React, { useState } from "react";
import "./SignUp.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaRegEye, FaEyeSlash } from "react-icons/fa";
import Header from "../../components/layout/header";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../constant";

const RecoverPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [email, setEmail] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleRecoverPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }
    try {
      const response = await axios.post(
        `${BASE_URL}/users/resetPassword?email=${email}&token=${token}&newPassword=${newPassword}`
      );
      console.log(response);
      toast.success("Password reset successful!");
      navigate("/login");
    } catch (error) {
      console.error(error.response);
      toast.error("Something went wrong! Try again later");
    }
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  return (
    <div className="signup-page">
      <Header />
      <div className="signup-main-container">
        <h2>
          Lost your Divine Key?
          <br /> Let's Get back in!
        </h2>
        <div className="signup-form-container">
          <form className="signup-form" style={{ padding: "50px" }}>
            <h3>Recover Password</h3>
            <p>
              We are here to remind you & help you change. If you forget your
              password.
            </p>
            <div className="inputs">
              <input
                type="text"
                placeholder="Email"
                className="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <button type="button" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <FaEyeSlash className="field-icon" size={25} />
                  ) : (
                    <FaRegEye className="field-icon" size={25} />
                  )}
                </button>
              </div>
              <div className="password-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="new-password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <button type="button" onClick={toggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? (
                    <FaEyeSlash className="field-icon" size={25} />
                  ) : (
                    <FaRegEye className="field-icon" size={25} />
                  )}
                </button>
              </div>
              {!passwordsMatch && (
                <p style={{ color: "red", textAlign: "left", margin: "0" }}>
                  Passwords do not match
                </p>
              )}
              <button
                type="submit"
                className="signup-btn"
                onClick={handleRecoverPassword}
              >
                Save Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
