import React from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import "./style.css";
import border from "../../images/border.png";

const TermsConditions = () => {
  return (
    <div>
      <Header />
      <div className="terms-container">
        <div className="heading">
          <h1>Terms & Conditions</h1>
          <img src={border} alt="img" id="border" loading="lazy" />
        </div>
        <p>
          Welcome to Divine Depot! These Terms & Conditions govern your access
          to and use of the Divine Depot website and its services. By accessing
          or using the Website or Services, you agree to be bound by these
          Terms. If you disagree with any part of these Terms, you may not
          access or use the Website or Services.
        </p>

        <h2>1. Eligibility</h2>
        <p>
          You must be at least 18 years old and have the legal capacity to enter
          into contracts to use the Website or Services. By using the Website or
          Services, you represent and warrant that you meet all of these
          eligibility requirements.
        </p>

        <h2>2. User Accounts</h2>
        <p>
          If you create an account on the Website, you are responsible for
          maintaining the confidentiality of your account information, including
          your login credentials, and for all activity that occurs under your
          Account. You agree to notify Divine Depot immediately of any
          unauthorized use of your Account or any other security breach. Divine
          Depot shall not be liable for any loss or damage arising from your
          failure to comply with these security obligations.
        </p>

        <h2>3. Acceptable Use</h2>
        <p>
          You agree to use the Website and Services only for lawful purposes and
          in accordance with these Terms. You agree not to use the Website or
          Services:
          <ul>
            <li>In any way that violates any applicable law or regulation.</li>
            <li>
              To upload, post, or transmit any content that is harmful,
              offensive, threatening, abusive, harassing, defamatory, obscene,
              hateful, or racially or ethnically objectionable.
            </li>
            <li>
              To infringe on any intellectual property right of any other person
              or entity.
            </li>
            <li>To impersonate any other person or entity.</li>
            <li>
              To interfere with or disrupt the Website or Services or any
              servers or networks connected to the Website or Services.
            </li>
            <li>
              To use the Website or Services to transmit spam, junk mail, or
              chain letters.
            </li>
            <li>
              To attempt to gain unauthorized access to the Website, Services,
              or any other accounts, computer systems, or networks connected to
              the Website or Services.
            </li>
            <li>
              To collect or store personal information about other users of the
              Website or Services without their express consent.
            </li>
          </ul>
        </p>

        <h2>4. User Content</h2>
        <p>
          You may be permitted to submit content to the Website. By submitting
          User Content, you grant Divine Depot a non-exclusive, irrevocable,
          royalty-free, worldwide license to use, reproduce, modify, publish,
          translate, distribute, and display such User Content in connection
          with the Website and Services. You are solely responsible for all User
          Content that you submit to the Website. You represent and warrant that
          you own all rights to the User Content or have obtained all necessary
          permissions and licenses to submit the User Content to the Website.
          You further represent and warrant that the User Content does not
          violate the rights of any third party.
        </p>

        <h2>5. Termination</h2>
        <p>
          Divine Depot reserves the right to terminate your access to the
          Website and Services at any time, for any reason, or no reason at all,
          with or without notice. Divine Depot may also, in its sole discretion,
          remove any User Content from the Website.
        </p>

        <h2>6. Disclaimer of Warranties</h2>
        <p>
          THE WEBSITE AND SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE"
          WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT
          NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, NON-INFRINGEMENT, OR COURSE OF DEALING. DIVINE DEPOT DOES NOT
          WARRANT THAT THE WEBSITE OR SERVICES WILL BE UNINTERRUPTED, SECURE, OR
          ERROR-FREE. DIVINE DEPOT DOES NOT WARRANT THAT THE RESULTS THAT MAY BE
          OBTAINED FROM THE USE OF THE WEBSITE OR SERVICES WILL BE ACCURATE OR
          RELIABLE.
        </p>

        <h2>7. Limitation of Liability</h2>
        <p>
          DIVINE DEPOT SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF
          OR RELATING TO YOUR USE OF THE WEBSITE OR SERVICES, INCLUDING BUT NOT
          LIMITED TO DAMAGES FOR LOSS OF PROFITS, DATA, GOODWILL, USE, OR OTHER
          INTANGIBLE LOSSES, EVEN IF DIVINE DEPOT HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES.
        </p>

        <h2>8. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless Divine Depot, its officers,
          directors, employees, agents, and licensors from and against any and
          all claims, losses, damages, liabilities, costs, and expenses arising
          out of or relating to your use of the Website or Services, or your
          violation of these Terms.
        </p>

        <h2>9. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of India.
        </p>

        <h2>10. Dispute Resolution</h2>
        <p>
          Any dispute arising out of or relating to these Terms or your use of
          the Website or Services shall be resolved by binding arbitration in
          accordance with the Arbitration and Conciliation Act, 1996. The
          arbitration shall be conducted in Punjab.
        </p>

        <h1>Return & Refund Policy</h1>
        <p>
          We want you to be happy with your purchase! This Return & Refund
          Policy outlines the conditions under which you can return an item and
          receive a refund.
        </p>

        <h2>1. Return Eligibility</h2>
        <p>
          You may return most new, unopened items sold by Divine Depot within
          7-10 days of delivery for a full refund. Items must be returned in
          their original packaging with all tags and labels attached. The
          following items are not eligible for return:
          <ul>
            <li>Used or opened items</li>
            <li>Items damaged after purchase</li>
            <li>Items without original packaging or tags</li>
            <li>Clearance items</li>
          </ul>
        </p>

        <h2>2. Return Process</h2>
        <p>
          To initiate a return, please contact Divine Depot customer support at
          +91-6283013500 or info@divinedepot.in. We will provide you with a
          return shipping label and instructions on how to return your item.
        </p>

        <h2>3. Shipping Costs</h2>
        <p>
          Return shipping costs will be:
          <ul>
            <li>
              Covered by Divine Depot if the return is due to our error (e.g.,
              incorrect item shipped).
            </li>
            <li>
              Covered by you if the return is for any other reason (e.g.,
              unwanted item, changed mind).
            </li>
          </ul>
        </p>

        <h2>4. Refunds</h2>
        <p>
          Once we receive your returned item and verify that it meets the return
          eligibility requirements, we will issue a refund to your original
          payment method within 7-10 business days.
        </p>

        <h2>5. Order Cancellation</h2>
        <p>
          You may cancel your order online or by contacting customer support
          before your order ships. If your order has already shipped, you may
          still return it according to the Return Process outlined above.
        </p>

        <h2>6. Terms & Conditions</h2>
        <p>
          Please refer to our Terms & Conditions for additional information
          about your rights and responsibilities as a user of the Divine Depot
          website and services.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
