import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import img1 from "../../images/Group 28.png";
import { IoStar } from "react-icons/io5";
import "./OrderHistory.css";
import axios from "axios";
import { message } from "antd";
import { BASE_URL } from "../../constant";
import Modal from "react-bootstrap/Modal";
import icon1 from "../../images/icon1.png";
import icon2 from "../../images/icon2.png";
import icon3 from "../../images/icon3.jpg";
import icon4 from "../../images/icon4.webp";
import icon5 from "../../images/icon6.webp";
import { useNavigate } from "react-router-dom";
import orderImg from "../../images/order-icon.jpg";
import moment from "moment";

const OrderHistory = () => {
  const navigate = useNavigate();
  const stars = Array.from({ length: 5 });
  const [orders, setOrders] = useState([]);
  const [showAddress, setShowAddress] = useState(false);
  const [returnRequests, setReturnRequests] = useState([]);
  const [showReturn, setShowReturn] = useState(false);
  const [returnOrder, setReturnOrder] = useState({
    reason: "",
    comment: "",
    accountNumber: "",
    ifscCode: "",
    bankName: "",
    accountHolderName: "",
  });

  const [returnSerialNo, setReturnSerialNo] = useState(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [cancelSerialNo, setCancelSerialNo] = useState(null);

  useEffect(() => {
    fetchOrders();
    fetchReturnRequests();
  }, []);

  const handleAddressClose = () => setShowAddress(false);
  const handleAddressShow = () => {
    setShowAddress(true);
  };

  const handleReturnClose = () => setShowReturn(false);
  const handleReturnShow = (serialNo) => {
    setReturnSerialNo(serialNo);
    setShowReturn(true);
  };

  const handleCancelConfirmClose = () => setShowCancelConfirm(false);
  const handleCancelConfirmShow = (serialNo) => {
    setCancelSerialNo(serialNo);
    setShowCancelConfirm(true);
  };

  const fetchOrders = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(
        `${BASE_URL}/orders/summary?userId=${userId}`
      );
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchReturnRequests = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/return-request/all`);
      const updatedReturnRequests = response.data.map((request) => {
        if (request.refundConfirmed) {
          // Update return status if refundConfirmed is true
          return { ...request, returnInitiated: false, returnConfirmed: true };
        }
        return request;
      });
      setReturnRequests(updatedReturnRequests);
    } catch (error) {
      console.error("Error fetching return requests:", error);
    }
  };

  const getStatusIcon = (status) => {
    switch (status.toLowerCase()) {
      case "ordered":
        return icon3;
      case "shipped":
        return icon4;
      case "delivered":
        return icon1;
      case "canceled":
        return icon5;
      case "return initiated":
        return icon2;
      case "refund credited":
      default:
        return icon1;
    }
  };

  const getOrderStatus = (order) => {
    const returnRequest = returnRequests.find(
      (request) => request.order.serialNo == order.orders[0].serialNo
    );
    if (returnRequest) {
      if (returnRequest.returnConfirmed) {
        return `REFUND CREDITED `;
      } else if (returnRequest.refundInitiated) {
        return `RETURN INITIATED `;
      }
    }
    return order.orders[0].orderStatus;
  };

  const handleCancelOrder = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/orders/status/${cancelSerialNo}?newStatus=CANCELED`
      );

      message.success("Order Cancelled");
      window.location.reload();
    } catch (error) {
      console.error("Error updating order status:", error);
    }
    setShowCancelConfirm(false);
  };

  const handleReturnOrderSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/api/return-request/${returnSerialNo}`,
        returnOrder
      );
      message.success("Return Initiated");
      await fetchReturnRequests();
      window.location.reload();
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleReturnOrderChange = (e) => {
    const { name, value } = e.target;
    setReturnOrder({ ...returnOrder, [name]: value });
  };

  return (
    <div className="order-history-page">
      <Header />
      <h2>Orders and Returns</h2>
      {orders.length > 0 ? (
        <div className="order-history-container">
          <h3>All Orders</h3>
          {orders.map((order, index) => {
            const firstPhotoUrl =
              order.orders[0].productObject.productPhotos &&
              order.orders[0].productObject.productPhotos.length > 0
                ? order.orders[0].productObject.productPhotos[0].photoName
                : img1;

            const orderStatus = getOrderStatus(order);
            const hasReturnRequest = returnRequests.some(
              (request) => request.order.serialNo == order.orders[0].serialNo
            );
            return (
              <React.Fragment>
                <div className="order-status">
                  {hasReturnRequest ? (
                    <img src={icon2} alt="Return Icon" />
                  ) : (
                    <img
                      src={getStatusIcon(orderStatus)}
                      alt="Order Status Icon"
                    />
                  )}
                  <div>
                    <h6>{orderStatus}</h6>
                    {!hasReturnRequest && (
                      <p>
                        {order.orders[0].statusUpdateDate
                          ? moment(order.orders[0].statusUpdateDate).format(
                              "MMMM Do, YYYY"
                            )
                          : ""}
                      </p>
                    )}
                    {returnRequests.map((request) => {
                      if (request.order.serialNo === order.orders[0].serialNo) {
                        if (request.returnConfirmed) {
                          return (
                            <p>
                              {moment(request.returnConfirmDate).format(
                                "MMMM Do, YYYY"
                              )}
                            </p>
                          );
                        } else if (request.refundInitiated) {
                          return (
                            <p>
                              {moment(request.returnInitiateDate).format(
                                "MMMM Do, YYYY"
                              )}
                            </p>
                          );
                        }
                      }
                    })}
                  </div>
                </div>
                <div className="order-history-product" key={index}>
                  <div>
                    <img src={firstPhotoUrl} loading="lazy" />
                  </div>
                  <div className="order-description">
                    <div className="order-history-product-desc">
                      <h5>
                        {order.orders[0].productName} -
                        <span>
                          {order.orders[0].productObject.prdDesc?.slice(0, 100)}
                          ...
                        </span>
                      </h5>

                      <div className="order-details">
                        <div className="order-detail1">
                          <div className="order-date">
                            <p>Order Placed</p>
                            <p>
                              {new Date(
                                order.orders[0].billDate
                              ).toDateString()}
                            </p>
                          </div>
                          <div className="order-id">
                            <p>Order Id</p>
                            <p>#{order.orders[0].serialNo}</p>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="view-order-address"
                          onClick={() => handleAddressShow()}
                        >
                          View Address
                        </button>
                        {order.orders[0].orderStatus == "DELIVERED" && (
                          <button
                            type="submit"
                            className="return-order-btn"
                            onClick={() =>
                              handleReturnShow(order.orders[0].serialNo)
                            }
                          >
                            Return order
                          </button>
                        )}
                        {order.orders[0].orderStatus !== "DELIVERED" && (
                          <button
                            type="submit"
                            className="cancel-order-btn"
                            onClick={() =>
                              handleCancelConfirmShow(order.orders[0].serialNo)
                            }
                          >
                            Cancel order
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="order-history-product-price">
                      <div className="order-history-product-rating">
                        <div>
                          <h4>Amount</h4>
                        </div>

                        <p>₹ {order.orders[0].finalOrderAmount}.00</p>
                      </div>
                    </div>
                  </div>
                  <Modal
                    show={showAddress}
                    onHide={handleAddressClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Shipping Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2,1fr)",
                        }}
                      >
                        {/* Display the address details */}
                        <p>Name: {order.addressId.name}</p>
                        <p>Mobile No: {order.addressId.mobile}</p>
                        <p>House No: {order.addressId.houseName}</p>
                        <p>Street: {order.addressId.street}</p>
                        <p>Landmark: {order.addressId.landMark}</p>
                        <p>City: {order.addressId.city}</p>
                        <p>State: {order.addressId.state}</p>
                        <p>Pincode: {order.addressId.pinCode}</p>
                        <p>Country: {order.addressId.country} </p>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <Modal
                    show={showReturn}
                    onHide={handleReturnClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Return Your Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form>
                        <input
                          type="text"
                          placeholder="Return reason"
                          className="reason"
                          name="reason"
                          value={returnOrder.reason}
                          onChange={handleReturnOrderChange}
                        />

                        <textarea
                          type="text"
                          placeholder="Comment"
                          className="comment"
                          name="comment"
                          value={returnOrder.comment}
                          onChange={handleReturnOrderChange}
                        />
                        <div className="account-details">
                          <h4>Bank Account Details</h4>
                          <input
                            type="text"
                            placeholder="Bank Account no."
                            className="reason"
                            name="accountNumber"
                            value={returnOrder.accountNumber}
                            onChange={handleReturnOrderChange}
                          />
                          <input
                            type="text"
                            placeholder="Account Holder Name "
                            className="reason"
                            name="accountHolderName"
                            value={returnOrder.accountHolderName}
                            onChange={handleReturnOrderChange}
                          />
                          <input
                            type="text"
                            placeholder="Bank Name"
                            className="reason"
                            name="bankName"
                            value={returnOrder.bankName}
                            onChange={handleReturnOrderChange}
                          />
                          <input
                            type="text"
                            placeholder="IFSC Code"
                            className="reason"
                            name="ifscCode"
                            value={returnOrder.ifscCode}
                            onChange={handleReturnOrderChange}
                          />
                        </div>
                        <p>
                          Read our{" "}
                          <a href="/" style={{ color: "red" }}>
                            Return Policy
                          </a>{" "}
                          before returning your order.
                        </p>
                        <button
                          onClick={handleReturnOrderSubmit}
                          className="refund-submit-btn"
                        >
                          Submit
                        </button>
                      </form>
                    </Modal.Body>
                  </Modal>
                  <Modal
                    show={showCancelConfirm}
                    onHide={handleCancelConfirmClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Cancel Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>Are you sure you want to cancel this order?</p>
                      <div className="modal-actions">
                        <button
                          onClick={handleCancelOrder}
                          className="confirm-cancel-btn"
                        >
                          Yes
                        </button>
                        <button
                          onClick={handleCancelConfirmClose}
                          className="cancel-btn"
                        >
                          No
                        </button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <div className="order-history-container">
          <div className="empty-wishlist">
            <img src={orderImg} width={"200px"} />
            <h3 style={{ fontSize: "30px" }}>No Orders Yet!</h3>
            <p style={{ fontSize: "20px" }}>
              Looks like you haven't made your choice yet...
            </p>
            <button
              className="empty-cart-btn"
              type="submit"
              onClick={() => navigate("/cart")}
            >
              Start Shopping
            </button>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default OrderHistory;
