import { FaCartShopping, FaBars, FaUser } from "react-icons/fa6";
import { CiCircleRemove } from "react-icons/ci";
import { CgSearch } from "react-icons/cg";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constant";
import axios from "axios";
import { Badge } from "antd";
import { useCartContext } from "../../Context/CartContext";
import logo from "../../images/ddnavbaricon.png";

const Header = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [resMenu, setResMenu] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { cartCount, fetchCartCount } = useCartContext();

  useEffect(() => {
    fetchCartCount();
  }, []);

  const handleSearchProduct = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(
        `${BASE_URL}/products/filter?userId=${userId}&searchName=${searchTerm}`
      );
      console.log(response.data);
      navigate(`/shop?search=${searchTerm}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const userId = localStorage.getItem("userId");
  const handleLogout = async () => {
    localStorage.removeItem("userId");
    await fetchCartCount();
    toast.success("Logout successfully");

    navigate("/");
  };

  const handleResMenuToggle = () => {
    setResMenu(!resMenu);
  };

  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
  };
  return (
    <div>
      <nav>
        <input type="checkbox" name="check" id="check" checked={menuOpen} />
        <label htmlFor="check" className="checkbtn" onClick={handleMenuToggle}>
          {menuOpen ? <CiCircleRemove /> : <FaBars />}
        </label>
        <div className="logo" onClick={() => navigate("/")}>
          <Link to="/">
            <img src={logo} />
          </Link>
        </div>

        <ul className={menuOpen ? "show" : ""}>
          <li>
            <Link to="/" className="link">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="link">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/shop" className="link">
              Our Shop
            </Link>
          </li>
          <li>
            <Link to="/contact" className="link">
              Contact Us
            </Link>
          </li>

          {/*only for responsive mode */}

          <li className="responsive">
            <Badge id="res-cart-badge" count={cartCount} showZero>
              <Link to="/cart" className="link">
                Cart
              </Link>
            </Badge>
          </li>

          {userId ? (
            <li className="responsive">
              <Link
                to=""
                className="link"
                id="res-profile"
                onClick={handleResMenuToggle}
              >
                User Dashboard <IoIosArrowDown />
                {resMenu && (
                  <ul className="res-menu">
                    <li>
                      <Link to={"/profile"}>Account</Link>
                    </li>
                    <li>
                      <Link to={"/order-history"}>Orders</Link>
                    </li>
                    <li>
                      <Link to={"/wishlist"}>Wishlist</Link>
                    </li>
                    <li>
                      <Link to={"/"} onClick={handleLogout}>
                        Logout
                      </Link>
                    </li>
                  </ul>
                )}
              </Link>
            </li>
          ) : (
            <li className="responsive">
              <Link to="/login" className="link">
                Sign up/ Login
              </Link>
            </li>
          )}
        </ul>
        <div className="nav-icons">
          <Link to="" className="search-icon" onClick={handleSearchToggle}>
            <CgSearch size={30} />
          </Link>
          <Badge id="cart-badge" count={cartCount} showZero>
            <Link to="/cart" className="cart-icon">
              <FaCartShopping size={25} />
            </Link>
          </Badge>
          {userId ? (
            <React.Fragment>
              <Link to="" className="user-icon">
                <FaUser size={25} />
              </Link>
              <div className="user-menu">
                <ul>
                  <li>
                    <Link to={"/profile"} id="user-link">
                      Account
                    </Link>
                  </li>
                  <li>
                    <Link to={"/order-history"} id="user-link">
                      Orders
                    </Link>
                  </li>
                  <li>
                    <Link to={"/wishlist"} id="user-link">
                      Wishlist
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"} id="user-link" onClick={handleLogout}>
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          ) : (
            <Link to="/login" className="btn">
              Log in/Sign up
            </Link>
          )}
        </div>
      </nav>
      {searchOpen && (
        <form className="search-form">
          <input
            type="text"
            id="search-box"
            placeholder="Search here..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearchProduct();
              }
            }}
          />
          <button type="button" onClick={handleSearchProduct}>
            <CgSearch size={25} />
          </button>
        </form>
      )}
    </div>
  );
};

export default Header;
