import React, { useEffect, useState } from "react";
import { IoStar } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import img3 from "../../images/Group 28.png";
import { FaHeart } from "react-icons/fa";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import "./Wishlist.css";
import axios from "axios";
import { BASE_URL } from "../../constant";
import wishlistImg from "../../images/wishlist-icon.jpg";
import { toast } from "react-toastify";
import cogoToast from "cogo-toast";

const Wishlist = () => {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const stars = Array.from({ length: 5 });
  const [wishlistItems, setWishlistItems] = useState([]);

  // Function to fetch wishlist items
  const fetchWishlistItems = async () => {
    if (userId) {
      try {
        const response = await axios.get(`${BASE_URL}/wishlist/get/${userId}`);
        setWishlistItems(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    }
  };

  useEffect(() => {
    fetchWishlistItems();
  }, []);

  const removeWishlistItem = async (productId) => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.delete(
        `${BASE_URL}/wishlist/delete/${userId}/${productId}`
      );
      fetchWishlistItems();
      console.log(response);
      cogoToast.error("Product removed from wishist");
    } catch (error) {
      console.error("Error removing product from wishlist:", error);
      toast.error("Something went wrong! Try again later");
    }
  };

  const handleClearWishlist = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.delete(
        `${BASE_URL}/wishlist/delete-all/${userId}/clear`
      );
      setWishlistItems([]);
      console.log(response);

      toast.success("Wishlist Cleared");
    } catch (error) {
      console.error("Error Clearing wishlist:", error);
      toast.error("Something went wrong! Try again later");
    }
  };

  const handleAddToCart = async (productId) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const cartResponse = await axios.post(
          `${BASE_URL}/usercart/add-product?userId=${userId}&productId=${productId}&orderQuantity=1`
        );
        await removeWishlistItem(productId);
        toast.success("Product added to cart");
        navigate("/cart");
      } catch (error) {
        console.error("Error adding product to cart:", error);
        toast.error("Something went wrong! Try again later");
      }
    } else {
      navigate("/signup");
    }
  };

  return (
    <div
      style={{ background: "linear-gradient(45deg, #efecca, #f7f3df, white)" }}
    >
      <Header />
      <div className="wishlist-container">
        {wishlistItems.length > 0 ? (
          <div className="wishlist-top">
            <h1>Your Wishlist</h1>
            <button
              onClick={handleClearWishlist}
              className="wishlist-clear-btn"
            >
              Clear Wishlist
            </button>
          </div>
        ) : (
          ""
        )}

        {wishlistItems.length > 0 ? (
          <div className="wishlist-product-items">
            {wishlistItems.map((item) => {
              const firstPhotoUrl =
                item.product.productPhotos &&
                item.product.productPhotos.length > 0
                  ? item.product.productPhotos[0].photoName
                  : img3;
              return (
                <div key={item.productId} className="wishlist-product-card">
                  <div className="wishlist-product-image">
                    <img
                      src={firstPhotoUrl}
                      alt={""}
                      onClick={() => navigate("/productdetails")}
                    />
                  </div>
                  <div className="wishlist-product-info">
                    <h2>{item.product.productName}</h2>
                    <p className="wishlist-product-description">
                      {item.product.prdDesc?.slice(0, 50)}...
                    </p>
                    <p className="wishlist-product-icons">
                      {stars.map((_, index) => (
                        <IoStar key={index} />
                      ))}
                      (145)
                    </p>
                    <div className="wishlist-product-card-btn">
                      <button
                        className="wishlist-add-to-cart"
                        onClick={() => handleAddToCart(item.productId)}
                      >
                        Add to Cart
                      </button>
                      <button
                        className="wishlist-add-to-wishlist"
                        onClick={() => removeWishlistItem(item.productId)}
                      >
                        <FaHeart size={30} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="empty-wishlist">
            <img src={wishlistImg} width={"200px"} />
            <h3 style={{ fontSize: "30px" }}>Your Wishlist is Empty!</h3>
            <p style={{ fontSize: "20px" }}>
              Add items in your wishlist to buy them later.
            </p>
            <button
              className="empty-cart-btn"
              type="submit"
              onClick={() => navigate("/shop")}
            >
              Add Products
            </button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Wishlist;
