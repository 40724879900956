import React, { useState } from "react";
import "./ContactPage.css";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import { CiMail } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { HiOutlineLocationMarker } from "react-icons/hi";
import pattern4 from "../../images/pattern4.png";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../constant";
import { useNavigate } from "react-router-dom";

export const ContactPage = () => {
  const navigate = useNavigate();
  const [messageReason, setMessageReason] = useState("");
  const [reqMessage, setReqMessage] = useState("");
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const handleContactForm = async (e) => {
    e.preventDefault();

    const mobileNumberPattern = /^\d{10}$/;

    if (!mobileNumberPattern.test(phoneNo)) {
      toast.error("Please enter a valid 10-digit mobile number");
      return;
    }

    try {
      const userId = localStorage.getItem("userId");
      if (userId) {
        const response = await axios.post(
          `${BASE_URL}/customerMessage/add?userId=${userId}&messageReason=${messageReason}&reqMessage=${reqMessage}&name=${name}&phoneNo=${phoneNo}`
        );
        console.log(response.data);

        const { errorMessage } = response.data;
        if (errorMessage) {
          toast.error(errorMessage);
        } else {
          toast.success("Message sent!");
          setName("");
          setPhoneNo("");
          setMessageReason("");
          setReqMessage("");
        }
      } else {
        navigate("/signup");
      }
    } catch (error) {
      console.error(error.response.data);
      toast.error("Something went wrong! Try again later");
    }
  };
  return (
    <div>
      <Header />
      <div className="contact-banner">
        <h1>Contact Us</h1>
      </div>
      <img src={pattern4} className="pattern4-bg" />
      <div className="contact-form-container">
        <div className="contact-form">
          <h2>Leave Us A Message</h2>
          <h5>Nourishing nature, nurturing you</h5>
          <form method="post" onSubmit={handleContactForm}>
            <div className="form-group" id="grp1">
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                required
              />
              <input
                type="text"
                name="phoneNo"
                value={phoneNo}
                onChange={(e) => {
                  const enteredValue = e.target.value.replace(/\D/, "");
                  setPhoneNo(enteredValue);
                }}
                placeholder="Phone No."
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="messageReason"
                value={messageReason}
                onChange={(e) => setMessageReason(e.target.value)}
                placeholder="Subject"
                required
              />
            </div>
            <div className="form-group">
              <textarea
                name="reqMessage"
                placeholder="Message"
                value={reqMessage}
                onChange={(e) => setReqMessage(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input type="submit" value={"Submit"} />
            </div>
          </form>
        </div>
      </div>
      <div className="contact-info-container">
        <div className="contact-info">
          <IoCallOutline className="con-icon" />
          <div id="contact-text">
            <p>Phone number</p>
            <p>+91 6283013500</p>
            <p style={{ fontSize: "18px" }}>Call us between 9am - 6pm</p>
          </div>
        </div>
        <div className="contact-info">
          <CiMail className="con-icon" />
          <div id="contact-text">
            <p>Email address</p>
            <p>info@divinedepot.in</p>
          </div>
        </div>
        <div className="contact-info">
          <HiOutlineLocationMarker className="con-icon" />
          <div id="contact-text">
            <p>Address</p>
            <p>
              Radha Nikunj Bihari Industries 145, SG Enclave Majitha Road
              Amritsar .
            </p>
          </div>
        </div>
      </div>
      <div className="map-container">
        {/*<div className="timings">
          <div className="timing-btn">
            <button> Hours</button>
          </div>
          <h4>Monday - Friday</h4>
          <h5>9.00 AM - 6.30 PM</h5>
          <h4>Saturdays</h4>
          <h5>11.00 AM - 6.30 PM</h5>
          <h4>Sunday</h4>
          <h5>11.00 AM - 6.30 PM</h5>
        </div>*/}
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3395.2584615100336!2d74.90083153346978!3d31.681501507464034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391963a6850b2a99%3A0x5de3cacd209287e5!2sS.G.Enclave%2C%20Majitha%20Rd%2C%20Phase-I%2C%20Pandori%20Waraich%2C%20Amritsar%2C%20Punjab%20143001!5e0!3m2!1sen!2sin!4v1719648491976!5m2!1sen!2sin"
            width="800"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};
