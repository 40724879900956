import React from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import img from "../../images/image 144.png";
import "./payment.css";
import { useNavigate } from "react-router-dom";

const PaymentSuccessfull = () => {
  const navigate = useNavigate();
  return (
    <div className="payment-status-page">
      <div>
        <Header />
        <div className="payment-status-container">
          <h2>Thank You!</h2>
          <img src={img} />
          <p>Your payment is successful Thank you for placing this order..</p>
          <button type="submit" onClick={() => navigate("/shop")}>
            Continue Shopping
          </button>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentSuccessfull;
