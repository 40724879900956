import React, { useState } from "react";
import "./SignUp.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/header";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../constant";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/users/forgotPassword?email=${email}`
      );
      console.log(response);
      toast.success("Link sent! Check your inbox");
      navigate("/recover-password");
    } catch (error) {
      console.error(error.response);
      toast.error("Something went wrong!Try again later");
    }
  };
  return (
    <div className="signup-page">
      <Header />
      <div className="signup-main-container">
        <h2>
          Unlock Your Account
          <br /> Access
        </h2>
        <div className="signup-form-container">
          <form className="signup-form" style={{ padding: "50px" }}>
            <h3>Your password can be changed</h3>
            <p>
              Share your e-mail ID with us. So we can drop a password recover
              link in your inbox.
            </p>
            <div className="inputs">
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginBottom: "30px" }}
              />
              <button type="submit" className="signup-btn" onClick={sendEmail}>
                Send Mail
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
