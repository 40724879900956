import React from "react";
import instaLogo from "../../images/insta.png";
import whtsLogo from "../../images/whts.png";
import facebookLogo from "../../images/facebook.png";
import { MdOutlineMail, MdPhone } from "react-icons/md";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="logo" id="footer-logo">
        <h4 style={{ color: "white", textAlign: "center", paddingTop: "20px" }}>
          Radha Nikunj Bihari Industries
        </h4>
        <p style={{ color: "white", textAlign: "center", fontSize: "18px" }}>
          145 SG Enclave Majitha road Amritsar 143001
        </p>
      </div>
      <ul style={{ marginTop: "20px" }}>
        <li>
          <Link to="/" id="footer-link">
            Home{" "}
          </Link>
        </li>
        <li>
          <Link to="/about" id="footer-link">
            {" "}
            About Us{" "}
          </Link>
        </li>
        <li>
          <Link to="/shop" id="footer-link">
            Our Shop{" "}
          </Link>
        </li>
        <li>
          <Link to="/contact" id="footer-link">
            Contact Us
          </Link>
        </li>
        <li>
          <Link to="/terms&conditions" id="footer-link">
            Terms & Conditions
          </Link>
        </li>
        <li>
          <Link to="/privacy-policy" id="footer-link">
            Privacy Policy
          </Link>
        </li>
      </ul>
      <div className="social-icons">
        <a href="https://www.instagram.com/divinedepot/" target="blank">
          <img src={instaLogo} alt="instagram" />
        </a>
        <a href="https://wa.me/916283013500" target="blank">
          <img src={whtsLogo} alt="whatsapp" />
        </a>
        <a
          href="https://www.facebook.com/divine.depot.2005?mibextid=ZbWKwL"
          target="blank"
        >
          <img src={facebookLogo} alt="facebook" />
        </a>
      </div>
      <div className="info">
        <span>
          <p style={{ textAlign: "center", marginBottom: "0" }}>
            ESTIMATED DELIVERY BETWEEN :- 7-10 Days
          </p>
          <p>
            <MdOutlineMail size={20} /> Email Id: info@divinedepot.in
          </p>
        </span>
        <span>
          <p>
            Copyright © Divine Depot and developed by{" "}
            <a
              style={{ color: "white", textDecoration: "none" }}
              href="https://golokait.com/"
            >
              Goloka IT.
            </a>
          </p>
        </span>
        <span>
          <p style={{ textAlign: "center", marginBottom: "0" }}>
            Call between 9am - 6pm
          </p>
          <p>
            <MdPhone size={20} /> Phone Number: +91-6283013500
          </p>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
