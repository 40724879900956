import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Image1 from ".././images/Img1.png";
import Image2 from ".././images/Img2.png";
import Image3 from ".././images/Img3.png";
import Image4 from ".././images/Img4.png";

const CarouselSection = () => {
  return (
    <div>
      <Carousel className="carousel" controls={false} indicators={true} fade>
        <Carousel.Item interval={800}>
          <img
            src={Image1}
            alt="img1"
            text="First slide"
            className="d-block w-100 object-fit-cover"
          />
          <h1 className="carousel-heading">
            Welcome to Divine Depot:
            <br />
            Your Portal to Spiritual Bliss!
          </h1>
        </Carousel.Item>
        <Carousel.Item interval={800}>
          <img
            src={Image2}
            alt="img2"
            text="Second slide"
            className="d-block w-100 object-fit-cover"
          />
          <h1 className="carousel-heading">
            Explore Sacred Wisdom:
            <br />
            Discover Divine Depot Today!
          </h1>
        </Carousel.Item>
        <Carousel.Item interval={800}>
          <img
            src={Image3}
            alt="img3"
            text="Third slide"
            className="d-block w-100 object-fit-cover"
          />
          <h1 className="carousel-heading">
            Journey to Enlightenment:
            <br />
            Start Exploring Now!
          </h1>
        </Carousel.Item>
        <Carousel.Item interval={800}>
          <img
            src={Image4}
            alt="img4"
            text="Third slide"
            className="d-block w-100 object-fit-cover"
          />
          <h1 className="carousel-heading">
            Embrace Inner Harmony:
            <br />
            Your Portal to Spiritual Bliss
          </h1>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselSection;
