import React from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import "./style.css";
import border from "../../images/border.png";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="terms-container">
        <div className="heading">
          <h1>Privacy Policy</h1>
          <img src={border} alt="img" id="border" loading="lazy" />
        </div>
        <p>
          Divine Depot "we" are committed to protecting the privacy of our users
          . This Privacy Policy explains how we collect, use, disclose, and
          store your personal information when you use our website and services.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We collect several types of information from and about users of our
          Website and Services:
        </p>
        <ol className="info-pp">
          <li>
            <b>Information you provide directly:</b> This includes information
            you provide when you create an account, place an order, contact
            customer support, or participate in surveys or other features of the
            Website or Services. This information may include your name, billing
            and shipping address, email address, phone number, and payment
            information.
          </li>
          <li>
            <b>Information collected automatically:</b> When you use the Website
            or Services, we may automatically collect certain information about
            your device and usage, such as your IP address, browser type,
            operating system, referring URL, pages visited, and time spent on
            the Website or Services.
          </li>
          <li>
            <b>Information from third parties:</b> We may collect information
            about you from third-party sources, such as social media platforms,
            if you connect your account to one of these services.
          </li>
        </ol>

        <h2>2. Use of Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <ol className="info-pp">
          <li>To provide and operate the Website and Services.</li>
          <li>To process your orders and transactions.</li>
          <li>To send you promotional emails and communications.</li>
          <li>To personalize your experience on the Website and Services.</li>
          <li>To improve the Website and Services.</li>
          <li>To analyze how users use the Website and Services.</li>
          <li>To comply with the law.</li>
        </ol>
        <h2>3. Disclosure of Information</h2>
        <p>
          We may disclose your information to third-party service providers who
          help us operate the Website and Services. These service providers are
          contractually obligated to keep your information confidential and
          secure. We may also disclose your information if we are required to do
          so by law or in the good faith belief that such disclosure is
          necessary to comply with a court order, subpoena, or other legal
          process, or to protect the rights, property, or safety of us or
          others.
        </p>

        <h2>4. Your Choices</h2>
        <p>
          You can opt out of receiving promotional emails from us by following
          the unsubscribe instructions in those emails. You can also access and
          update your personal information through your account settings.
        </p>

        <h2>5. Data Security</h2>
        <p>
          We take reasonable steps to protect your information from unauthorized
          access, disclosure, alteration, or destruction. However, no internet
          transmission or electronic storage is completely secure, so we cannot
          guarantee the security of your information.
        </p>

        <h2>6. Children's Privacy</h2>
        <p>
          Our Website and Services are not directed to children under the age of
          13. We do not knowingly collect personal information from children
          under 13. If you are a parent or guardian and you believe that your
          child has under 13 provided personal information to us, please contact
          us.
        </p>

        <h2>7. Changes to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will post any
          changes on the Website. You are advised to periodically review this
          Privacy Policy for any changes.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at info@divinedepot.in
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
