import React from "react";
import Header from "../components/layout/header";
import border from "../images/border.png";
import CategorySection from "../components/CategorySection";
import CarouselSection from "../components/CarouselSection";
import img1 from "../images/Rectangle 122.png";
import img2 from "../images/Rectangle 123.png";
import Footer from "../components/layout/footer";
import NewArrivals from "../components/New Arrivals/NewArrivals";
import ImageSlider from "../components/ImageSlider/ImageSlider";
import SliderHome from "../components/SacredOfferingsSlider/SliderHome";

const Home = () => {
  return (
    <React.Fragment>
      <Header />
      <CarouselSection />
      <section>
        <SliderHome />
        <CategorySection />
      </section>

      <section>
        <div
          className="headings"
          style={{ background: "#fdf8f8", paddingTop: "40px" }}
        >
          <h1>Journey Through Fragrant History</h1>
          <img src={border} width={"350px"} alt="" />
        </div>
        <div className="journey-container">
          <div id="part1">
            <div id="journey-img">
              <img src={img1} width={"400px"} />
            </div>
            <div className="journey-text">
              <h3>Discover Our Tradition</h3>
              <p>
                At Divine Depot , we blend age-old traditions with modern
                craftsmanship to create fragrant wonders. From the heart of
                India's natural bounty to your home, our agarbattis carry the
                essence of our heritage. Join us on a journey of scent and soul,
                where each stick tells a story of tradition, love, and
                tranquility.
              </p>
            </div>
          </div>
          <div id="part2">
            <div className="journey-text">
              <p>
                But our journey does not end there; it is merely the beginning
                of a new chapter in the saga of scent. With every breath, our
                agarbattis evoke memories of a bygone era, transporting you to a
                realm of serenity and tranquility. Whether it's the gentle
                whisper of lavender or the exotic allure of patchouli, each
                fragrance tells a story, a story of love, hope, and devotion.
              </p>
            </div>
            <div id="journey-img">
              <img src={img2} width={"400px"} />
            </div>
          </div>
        </div>
        <div className="quote-section">
          <h2 className="quote">
            "Whatever you do, make it an offering to Lord - the food you eat,
            the sacrifices you make, the help you give, and even your
            suffering."
          </h2>
        </div>
      </section>
      <NewArrivals />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
