import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaStar } from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import img6 from "../../images/Group 28.png";
import axios from "axios";
import { BASE_URL } from "../../constant.js";
import border from "../../images/border.png";
import { useNavigate } from "react-router-dom";
import "./NewArrival.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import SwiperCore from "swiper";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
SwiperCore.use([EffectCoverflow]);

function NewArrivals() {
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [initialSlide, setInitialSlide] = useState(0);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/products/allProductList`);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const centerSlideIndex = Math.floor(products.length / 2);
    setInitialSlide(centerSlideIndex);
  }, [products]);

  return (
    <section className="new-arrival-section">
      <div className="headings" style={{ paddingTop: "20px" }}>
        <h1>New Arrivals</h1>
        <img src={border} width={"350px"} alt="img" />
      </div>
      <Swiper
        effect={"coverflow"}
        centeredSlides={true}
        grabCursor={false}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        className="mySwiper"
        autoplay={{ delay: 1500 }}
        ref={swiperRef}
        initialSlide={initialSlide}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Navigation]}
      >
        {products.map((product) => {
          const firstPhotoUrl =
            product.productPhotos && product.productPhotos.length > 0
              ? product.productPhotos[0].photoName
              : img6;
          return (
            <SwiperSlide className="mySwiper" key={product.productId}>
              <div className="new-card">
                <img src={firstPhotoUrl} className="swiper-slide-image" />
                <div className="new-card-body">
                  <h3>{product.productName}</h3>
                  <p>{product.description}</p>
                  <p
                    onClick={() =>
                      navigate(`/productdetails/${product.productId}`)
                    }
                  >
                    {product.prdDesc?.slice(0, 90)}...
                  </p>
                  <div className="price">
                    <span style={{ display: "flex" }}>
                      <p>Rs {product.mrp}</p> <p> Rs {product.purRate}</p>
                    </span>
                    <span>
                      <FaStar style={{ color: "rgb(217, 189, 8)" }} size={20} />{" "}
                      {product.rating}
                    </span>
                  </div>
                  <button
                    className="new-card-btn"
                    onClick={() =>
                      navigate(`/productdetails/${product.productId}`)
                    }
                  >
                    Shop Now
                  </button>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <div className="slider_controller">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
        </div>
      </Swiper>
    </section>
  );
}

export default NewArrivals;
