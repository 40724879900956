import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { CiCircleRemove } from "react-icons/ci";
import { CgSearch } from "react-icons/cg";
import "./ShopPage.css";
import img3 from "../../images/Rectangle3.png";
import Slider from "react-slider";
import { FaRegHeart } from "react-icons/fa";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { IoStar } from "react-icons/io5";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../constant";
import { toast } from "react-toastify";
import { FaHeart } from "react-icons/fa6";
import queryString from "query-string";
import { useCartContext } from "../../Context/CartContext";

const PRODUCTS_PER_PAGE = 9;
const BUTTONS_PER_PAGE = 5;

const ShopPage = () => {
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false); // State to track sidebar visibility
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Featured");
  const location = useLocation();
  const [category, setCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // State to track current page
  const [stockStatus, setStockStatus] = useState("");
  const [values, setValues] = useState([0, 0]);
  const [dropdownStates, setDropdownStates] = useState({});
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [categories, setCategories] = useState([]);

  const [inStockCount, setInStockCount] = useState(0);
  const [outOfStockCount, setOutOfStockCount] = useState(0);

  const { search } = queryString.parse(location.search);
  const { fetchCartCount } = useCartContext();

  useEffect(() => {
    // Do something with the search term
    console.log("Search Term:", search);
  }, [search]);

  // changing category name according to category section in homepage
  useEffect(() => {
    if (location.state && location.state.category) {
      setCategory(location.state.category);
    }

    setSelectedItem("Featured");
  }, [location.state]);

  useEffect(() => {
    console.log("Category changed:", category);
    fetchProductsByHomeCategory();
  }, [category]);

  const fetchProductsByHomeCategory = async () => {
    if (!category) return;

    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(
        `${BASE_URL}/products/filter?userId=${userId}&category=${category}`
      );

      const data = response.data.content;
      setFilteredProducts(data);
      console.log("Fetched products by category:", data);
    } catch (error) {
      console.error("Error fetching products by category:", error);
    }
  };

  useEffect(() => {
    fetchCartItems();
    fetchWishlistItems();
    fetchCartCount();
    fetchCategories();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      const minPrice = Math.min(...products.map((product) => product.purRate));
      const maxPrice = Math.max(...products.map((product) => product.purRate));
      setValues([minPrice, maxPrice]);
    }
  }, [products]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/category/all-unhide`);
      const initialDropdownStates = {};
      response.data.forEach((category) => {
        initialDropdownStates[category.categoryName] = false;
      });
      setDropdownStates(initialDropdownStates);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Function to fetch cart items
  const fetchCartItems = async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const response = await axios.post(
          `${BASE_URL}/usercart/in-cart?userId=${userId}`
        );
        setCartItems(response.data);
        fetchProducts();
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    }
  };

  // Function to fetch wishlist items
  const fetchWishlistItems = async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const response = await axios.get(`${BASE_URL}/wishlist/get/${userId}`);
        setWishlistItems(response.data);
        console.log(response.data);
        fetchProducts();
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    }
  };

  const fetchProducts = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const url = search
        ? `${BASE_URL}/products/filter?userId=${userId}&searchName=${search}`
        : `${BASE_URL}/products/allProductList`;
      const response = await axios.get(url);
      const allProducts = search ? response.data.content : response.data;

      // Sort products based on the selected sorting criteria
      let sortedProducts = [...allProducts];
      if (selectedItem === "Price: low to high") {
        sortedProducts.sort((a, b) => a.purRate - b.purRate);
      } else if (selectedItem === "Price: high to low") {
        sortedProducts.sort((a, b) => b.purRate - a.purRate);
      } else if (selectedItem === "Newest arrivals") {
        sortedProducts.sort(
          (a, b) => new Date(b.createdTime) - new Date(a.createdTime)
        );
      }

      // Filter products based on stock status
      const filteredProducts = filterProductsByStock(
        sortedProducts,
        stockStatus
      );

      setProducts(filteredProducts);
      updateStockCounts(filteredProducts);
      console.log(sortedProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const updateStockCounts = (products) => {
    const inStockCount = products.filter(
      (product) => product.minStk > 0
    ).length;
    const outOfStockCount = products.filter(
      (product) => product.minStk == 0
    ).length;
    setInStockCount(inStockCount);
    setOutOfStockCount(outOfStockCount);
  };

  // Function to filter products based on stock status

  const filterProductsByStock = (products, status) => {
    if (status === "inStock") {
      return products.filter((product) => product.minStk > 0);
    } else if (status === "outOfStock") {
      return products.filter((product) => product.minStk == 0);
    } else {
      return products; // Return all products if no stock status is selected
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [stockStatus, selectedItem]);

  // Function to handle the selection of stock one at a time
  const handleStockStatusChange = (status) => {
    setStockStatus((prevState) => (prevState === status ? "" : status));
  };

  const fetchSearchProducts = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(
        `${BASE_URL}/products/filter?userId=${userId}&searchName=${searchInput}`
      );
      setProducts(response.data.content);
    } catch (error) {
      console.error("Error fetching search products:", error);
    }
  };

  // sidebar toggling for filters
  const handleSidebarToggle = () => {
    setShowSidebar(!showSidebar);
  };

  // sort button toggling for sort menu
  const toggleSortDropdown = () => {
    setIsSortOpen(!isSortOpen);
  };

  const handleSortItemClick = (item) => {
    setSelectedItem(item);
    setIsSortOpen(false);
    fetchProducts();
  };

  // displaying stars for rating
  const stars = Array.from({ length: 5 });

  // side bar dropdowns toggling
  const handleDropdownToggle = (dropdownName) => {
    console.log("Dropdown toggled for:", dropdownName);
    setDropdownStates((prevState) => ({
      ...Object.fromEntries(
        Object.entries(prevState).map(([name, value]) => [
          name,
          name === dropdownName ? !value : false,
        ])
      ),
    }));
  };

  // Calculate the index of the first and last product to display
  const indexOfLastProduct = currentPage * PRODUCTS_PER_PAGE;
  const indexOfFirstProduct = indexOfLastProduct - PRODUCTS_PER_PAGE;
  const currentProducts =
    filteredProducts.length > 0
      ? filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct)
      : products.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPageCount = Math.ceil(
    (filteredProducts.length > 0 ? filteredProducts.length : products.length) /
      PRODUCTS_PER_PAGE
  );

  const startPage = Math.max(
    1,
    Math.min(
      currentPage - Math.floor(BUTTONS_PER_PAGE / 2),
      totalPageCount - BUTTONS_PER_PAGE + 1
    )
  );
  const endPage = Math.min(totalPageCount, startPage + BUTTONS_PER_PAGE - 1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAddToCart = async (productId) => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      try {
        const cartResponse = await axios.post(
          `${BASE_URL}/usercart/add-product?userId=${userId}&productId=${productId}&orderQuantity=1`
        );
        await fetchCartCount();
        toast.success("Product added to cart");
        fetchCartItems();
      } catch (error) {
        console.error("Error adding product to cart:", error);
        toast.error("Something went wrong! Try again later");
      }
    } else {
      navigate("/login");
    }
  };

  const handleAddToWishlist = async (productId) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const wishlistResponse = await fetch(
          `${BASE_URL}/wishlist/add/${userId}`,

          {
            method: "POST",
            headers: {
              contentType: "application/json",
            },
            body: productId,
          }
        );
        console.log(productId);

        console.log(wishlistResponse.data);

        toast.success("Product added to wishlist");
        fetchWishlistItems();
      } catch (error) {
        console.error("Error adding product to wishlist:", error);
        toast.error("Something went wrong! Try again later");
      }
    } else {
      navigate("/login");
    }
  };

  const removeWishlistItem = async (productId) => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.delete(
        `${BASE_URL}/wishlist/delete/${userId}/${productId}`
      );
      console.log(response);

      toast.error("Product removed from wishist");
      fetchWishlistItems();
    } catch (error) {
      console.error("Error removing product from wishlist:", error);
      toast.error("Something went wrong! Try again later");
    }
  };

  const minPrice = Math.min(...products.map((product) => product.purRate));
  const maxPrice = Math.max(...products.map((product) => product.purRate));

  const fetchFilteredProductsByPrice = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const url = `${BASE_URL}/products/filter?userId=${userId}&minPrice=${values[0]}&maxPrice=${values[1]}`;
      const response = await axios.get(url);
      setProducts(response.data.content);
    } catch (error) {
      console.error("Error fetching filtered products:", error);
    }
  };

  const fetchFilteredProductsByCategory = async (categoryId, subcategoryId) => {
    try {
      const userId = localStorage.getItem("userId");
      const url = `${BASE_URL}/products/filter?userId=${userId}&category=${categoryId}&subcategory=${subcategoryId}`;

      const response = await axios.get(url);
      setProducts(response.data.content);
    } catch (error) {
      console.error("Error fetching filtered products:", error);
    }
  };

  const handleCategorySelection = (categoryId, subcategoryId) => {
    fetchFilteredProductsByCategory(categoryId, subcategoryId);
  };

  // Function to handle subcategory selection
  const handleSubcategorySelection = (subcategoryId) => {
    const category = categories.find((category) =>
      category.subcategoryList.some(
        (subcategory) => subcategory.subCategoryId == subcategoryId
      )
    );
    if (category) {
      fetchFilteredProductsByCategory(category.categoryId, subcategoryId);
    }
  };

  const categoryObject = categories.find((cat) => cat.categoryId == category);
  const categoryName = categoryObject
    ? categoryObject.categoryName
    : "All categories";
  console.log(categoryName);

  return (
    <div style={{ background: "#f3f2e7" }}>
      <Header />

      <div className="shop-container">
        {/* Sidebar Toggle Button */}

        <div id="sidebar" className={showSidebar ? "show" : ""}>
          <div className="res-side">
            <CiCircleRemove
              style={{ float: "right" }}
              size={30}
              id="handle-toggle-btn-2"
              onClick={handleSidebarToggle}
            />
            <div>
              <h5>Filter Product</h5>
              <p>Showing 194 of 194 products</p>
            </div>
          </div>
          <div className="dropdown-container">
            <h4>Shop By categories</h4>

            {categories.map((category) => (
              <React.Fragment>
                <h6 className="dropdown" key={category.categoryId}>
                  <span
                    onClick={() => handleDropdownToggle(category.categoryName)}
                  >
                    {category.categoryName}
                    {dropdownStates[category.categoryName] ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </span>
                  <ul
                    className={
                      dropdownStates[category.categoryName] ? "drop-show" : ""
                    }
                  >
                    {category.subcategoryList.map((subcategory) => (
                      <li
                        key={subcategory.subCategoryId}
                        onClick={() =>
                          handleSubcategorySelection(subcategory.subCategoryId)
                        }
                      >
                        {subcategory.subCategoryName}
                      </li>
                    ))}
                  </ul>
                </h6>
              </React.Fragment>
            ))}
          </div>
          <div className="checkbox-container">
            <h4>Filter By</h4>
            <h6>
              <input
                type="checkbox"
                className="filter-checkbox"
                checked={stockStatus === "inStock"}
                onChange={() => handleStockStatusChange("inStock")}
              />{" "}
              In Stock
              <span> ({inStockCount})</span>
            </h6>
            <h6>
              <input
                type="checkbox"
                className="filter-checkbox"
                checked={stockStatus === "outOfStock"}
                onChange={() => handleStockStatusChange("outOfStock")}
              />{" "}
              Out of Stock
              <span> ({outOfStockCount})</span>
            </h6>
          </div>
          <div className="price-slider-container">
            <Slider
              className={"price-slider"}
              value={values}
              min={minPrice}
              max={100}
              onChange={setValues}
              onAfterChange={fetchFilteredProductsByPrice}
            />
            <div className={"range-values"}>
              <span>Rs.{values[0]}</span>
              <span>Rs. {values[1]}</span>
            </div>
          </div>
        </div>

        {/* ------------------------------------products----------------------------*/}

        <div className="product-container">
          {search ? (
            <h5 style={{ fontFamily: "serif" }}>
              Showing results for: <b>{search}</b>
            </h5>
          ) : (
            ""
          )}
          <div id="category-name">
            <h4>{categoryName}</h4>
          </div>
          <div className="search">
            <div>
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search "
                  className="search-input"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="search-bar-icon">
                  <CgSearch size={25} onClick={fetchSearchProducts} />
                </div>
              </div>
            </div>
            <div className="view-type">
              <div className="sort-container">
                <button className="sort-btn" onClick={toggleSortDropdown}>
                  Sort by: {selectedItem}{" "}
                  {isSortOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </button>
                {isSortOpen && (
                  <div className="sort-menu">
                    <div
                      className="sort-item"
                      onClick={() => handleSortItemClick("Featured")}
                    >
                      Featured
                    </div>
                    <div
                      className="sort-item"
                      onClick={() => handleSortItemClick("Price: low to high")}
                    >
                      Price: low to high
                    </div>
                    <div
                      className="sort-item"
                      onClick={() => handleSortItemClick("Price: high to low")}
                    >
                      Price: high to low
                    </div>

                    <div
                      className="sort-item"
                      onClick={() => handleSortItemClick("Newest arrivals")}
                    >
                      Newest arrivals
                    </div>
                  </div>
                )}
              </div>

              <div>
                <h5
                  id="sidebar-toggle-btn"
                  onClick={handleSidebarToggle}
                  style={{ cursor: "pointer" }}
                >
                  {showSidebar ? (
                    <h5>
                      <HiOutlineAdjustmentsHorizontal /> Filters
                    </h5>
                  ) : (
                    <h5>
                      <HiOutlineAdjustmentsHorizontal /> Filters
                    </h5>
                  )}
                </h5>
              </div>
            </div>
          </div>
          {/*--------------------filter section for responsiven mode------------------------- */}

          <div className="product-items">
            {currentProducts.length > 0 ? (
              currentProducts.map((product) => {
                const firstPhotoUrl =
                  product.productPhotos && product.productPhotos.length > 0
                    ? product.productPhotos[0].photoName
                    : img3;

                return (
                  <div key={product.productId} className="product-card">
                    <div className="product-image">
                      <Link to={`/productdetails/${product.productId}`}>
                        <img
                          src={firstPhotoUrl}
                          alt={product.productName}
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <div className="product-info">
                      <h2>{product.productName}</h2>

                      <p className="product-description">
                        {product.brand ? product.brand : "Divine Depot"}

                        {product.minStk > 0 ? (
                          ""
                        ) : (
                          <span
                            style={{
                              color: "red",
                              fontSize: "18px",
                              marginLeft: "20px",
                            }}
                          >
                            Out of stock
                          </span>
                        )}
                      </p>
                      <div className="prod-price">
                        <span style={{ display: "flex" }}>
                          <p>Rs {product.mrp}</p> <p> Rs {product.purRate}</p>
                        </span>
                        <span>
                          <IoStar
                            style={{ color: "rgb(217, 189, 8)" }}
                            size={20}
                          />{" "}
                          4.3
                        </span>
                      </div>
                      <div className="product-card-btn">
                        {product.minStk > 0 ? (
                          cartItems.some(
                            (item) => item.productid === product.productId
                          ) ? (
                            <button className="prod-added-to-cart">
                              Added to Cart
                            </button>
                          ) : (
                            <button
                              className="add-to-cart"
                              onClick={() => handleAddToCart(product.productId)}
                            >
                              Add to Cart
                            </button>
                          )
                        ) : (
                          <button className="add-to-cart" disabled>
                            Add to cart
                          </button>
                        )}
                        {wishlistItems.some(
                          (item) => item.productId === product.productId
                        ) ? (
                          <button
                            className="add-to-wishlist"
                            onClick={() =>
                              removeWishlistItem(product.productId)
                            }
                          >
                            <FaHeart size={25} style={{ color: "red" }} />
                          </button>
                        ) : (
                          <button
                            className="add-to-wishlist"
                            onClick={() => {
                              handleAddToWishlist(product.productId);
                              console.log(product.productId);
                            }}
                          >
                            <FaRegHeart size={25} />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h3>No products found</h3>
            )}
          </div>
          <div className="pagination">
            <div className="pagination-desc">
              Showing{" "}
              {Math.min(
                indexOfFirstProduct + 1,
                filteredProducts.length > 0
                  ? filteredProducts.length
                  : products.length
              )}
              -
              {Math.min(
                indexOfLastProduct,
                filteredProducts.length > 0
                  ? filteredProducts.length
                  : products.length
              )}{" "}
              of{" "}
              {filteredProducts.length > 0
                ? filteredProducts.length
                : products.length}{" "}
              items
            </div>
            <div className="pagination-buttons">
              {startPage > 1 && (
                <button onClick={() => handlePageChange(startPage - 1)}>
                  &laquo;
                </button>
              )}
              {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                <button
                  key={startPage + index}
                  className={currentPage === startPage + index ? "active" : ""}
                  onClick={() => handlePageChange(startPage + index)}
                >
                  {startPage + index}
                </button>
              ))}
              {endPage < totalPageCount && (
                <button onClick={() => handlePageChange(endPage + 1)}>
                  &raquo;
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShopPage;
