import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import border from "../images/border.png";
import img1 from "../images/Group 28.png";
import { BASE_URL } from ".././constant.js";
import axios from "axios";

const CategorySection = () => {
  const navigate = useNavigate();
  const [showMoreCategories, setShowMoreCategories] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/category/all-unhide`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Function to navigate to ShopPage with selected category name
  const handleCategoryClick = (categoryName) => {
    navigate("/shop", { state: { category: categoryName } });
  };

  // Function to toggle the visibility of additional categories
  const toggleShowMoreCategories = () => {
    setShowMoreCategories(!showMoreCategories);
  };

  // Split categories into visible and hidden
  const visibleCategories = showMoreCategories
    ? categories
    : categories.slice(0, 6);

  return (
    <div>
      <section className="category-section">
        <div className="headings" style={{ paddingTop: "60px" }}>
          <h1>Categories</h1>
          <img src={border} width={"350px"} alt="img" />
        </div>
        <div className="main-grid-container">
          <div className="category-grid">
            {visibleCategories.map((category, index) => (
              <div
                className="cat-item"
                onClick={() => handleCategoryClick(category.categoryId)}
                key={index}
              >
                <img
                  src={category.categoryImage || img1}
                  alt={category.categoryName}
                />
                <h4>{category.categoryName}</h4>
              </div>
            ))}
          </div>
        </div>
        {!showMoreCategories && categories.length > 6 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              type="submit"
              className="viewall"
              onClick={toggleShowMoreCategories}
            >
              View All
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default CategorySection;
