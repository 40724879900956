import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./SignUp.css";
import { FaRegEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/header";
import axios from "axios";
import { BASE_URL } from "../../constant";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userType = "USER";
      const response = await axios.post(
        `${BASE_URL}/users/loginUser?emailId=${email}&password=${password}&userType=${userType}`
      );
      const { userId } = response.data;

      if (!userId) {
        toast.error(
          "User not found. Please check your credentials or sign up."
        );

        return;
      }

      localStorage.setItem("userId", userId);

      toast.success("Login successful!");

      navigate("/");

      console.log(response.data);
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
      console.log(error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="signup-page">
      <Header />
      <div className="signup-main-container">
        <h2>
          Access Your Divine
          <br /> World
        </h2>
        <div className="signup-form-container">
          <form className="signup-form" onSubmit={handleLogin}>
            <h3>Log in to your Account</h3>
            <p>Welcome back to divine depot</p>
            <div className="inputs">
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button type="button" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <FaEyeSlash className="field-icon" size={25} />
                  ) : (
                    <FaRegEye className="field-icon" size={25} />
                  )}
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="forgot-password">
                  <Link to={"/forgot-password"} id="forgot-link">
                    Forgot password?
                  </Link>
                </div>
              </div>
              <button type="submit" className="signup-btn">
                Log in
              </button>
              <div className="divider">
                <span>or</span>
              </div>
            </div>
            <div className="login-link">
              <span>
                Don't have an account? <Link to={"/signup"}>Sign up</Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
