import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { CiCirclePlus } from "react-icons/ci";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constant";

import "./ProfilePage.css";
export const ProfilePage = () => {
  const [show, setShow] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [user, setUser] = useState({
    email: "",
    name: "",
    mobile1: "",
    mobile2: "",
    address1: "",
    address2: "",
    address3: "",
  });
  const [newAddress, setNewAddress] = useState({
    addrId: 1,
    name: "",
    houseName: "",
    street: "",
    landMark: "",
    city: "",
    state: "",
    pinCode: "",
    countryCode: "",
    mobile: "",
    country: "",
    addrType: "Home",
  });
  const [existingAddress, setExistingAddress] = useState({
    addrId: 1,
    name: "",
    houseName: "",
    street: "",
    landMark: "",
    city: "",
    state: "",
    pinCode: "",
    countryCode: "",
    mobile: "",
    country: "",
    addrType: "Home",
  });
  const [addresses, setAddresses] = useState([]);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [addressErrors, setAddressErrors] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEditClose = () => setShowEditForm(false);
  const handleEditShow = (address) => {
    setExistingAddress({
      addrId: address.addrId,
      name: address.name,
      houseName: address.houseName,
      street: address.street,
      landMark: address.landMark,
      city: address.city,
      state: address.state,
      pinCode: address.pinCode,
      countryCode: address.countryCode,
      mobile: address.mobile,
      country: address.country,
      addrType: address.addrType,
    });
    setShowEditForm(true);
  };
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = (address) => {
    setShowDelete(true);
    setAddressToDelete(address);
  };

  // function for fetching user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const response = await axios.get(
          `${BASE_URL}/users/userByID/${userId}`
        );
        const userData = response.data;

        setUser({
          email: userData.email,
          name: userData.parName,
          mobile1: userData.mobile1,
          mobile2: userData.mobile2,
          address1: userData.address1,
          address2: userData.address2,
          address3: userData.address3,
        });

        console.log(userData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();

    fetchAddresses();
  }, []);

  // Function to fetch addresses
  const fetchAddresses = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(
        `${BASE_URL}/users/getAllAddresses/${userId}`
      );
      setAddresses(response.data);

      setExistingAddress(response.data);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };
  console.log(addresses);

  // function for handling value change of form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: name === "email" ? prevUser.email : value,
    }));
  };

  // function for posting and updating profile info
  const handleSave = async () => {
    try {
      if (!/^\d{10}$/.test(user.mobile1)) {
        toast.error("Please enter a valid 10-digit mobile number.");
        return;
      }

      // Validation for mobile2
      if (user.mobile2 && !/^\d{10}$/.test(user.mobile2)) {
        toast.error("Please enter a valid 10-digit mobile number.");
        return;
      }
      const userId = localStorage.getItem("userId");
      const userData = {
        parName: user.name,
        mobile1: user.mobile1,
        mobile2: user.mobile2,
      };
      console.log(userData);
      const savedResponse = await axios.post(
        `${BASE_URL}/users/updateUser/${userId}`,
        userData
      );

      if (savedResponse && savedResponse.data) {
        toast.success("Your profile has been updated!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // function for handling address value
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  // Function for posting address to backend
  const handleAddAddress = async (event) => {
    event.preventDefault();
    try {
      if (
        !newAddress.name ||
        !newAddress.houseName ||
        !newAddress.street ||
        !newAddress.landMark ||
        !newAddress.city ||
        !newAddress.state ||
        !newAddress.pinCode ||
        !newAddress.country ||
        !newAddress.countryCode ||
        !newAddress.mobile
      ) {
        setAddressErrors({
          allFields: "Please fill in all required fields.",
        });
        return;
      }

      // Validation for pin code
      if (!/^\d{6}$/.test(newAddress.pinCode)) {
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          pinCode: "Please enter a valid 6-digit pin code.",
        }));
        return;
      }

      // Validation for mobile number
      if (!/^\d{10}$/.test(newAddress.mobile)) {
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          mobile: "Please enter a valid 10-digit mobile number.",
        }));
        return;
      }

      const userId = localStorage.getItem("userId");

      // Fetching user's existing addresses
      const fetchResponse = await axios.get(
        `${BASE_URL}/users/getAllAddresses/${userId}`
      );
      const userAddresses = fetchResponse.data;

      let newAddressData;

      const existingAddrIds = userAddresses.map((address) => address.addrId);
      let newAddrId;
      for (let i = 1; i <= 3; i++) {
        if (!existingAddrIds.includes(i.toString())) {
          newAddrId = i;
          break;
        }
      }

      if (!newAddrId) {
        toast.error("You can't add more than three addresses.");
        return;
      }

      newAddressData = {
        ...newAddress,
        addrId: newAddrId.toString(),
      };

      console.log("Sending data to backend:", newAddressData);

      const addressRes = await axios.post(
        `${BASE_URL}/users/addNewAddress/${userId}`,
        newAddressData
      );

      console.log("Response from backend:", addressRes.data);
      handleClose();

      fetchAddresses();

      if (addressRes && addressRes.data) {
        toast.success("Address added successfully!");
      }
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Error adding address. Please try again.");
    }
  };

  //Function to handle deletion of address
  const handleDeleteAddress = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const addrId = addressToDelete.addrId;

      const deleteResponse = await axios.post(
        `${BASE_URL}/users/deleteAddress?userId=${userId}&addrId=${addrId}`
      );

      if (deleteResponse && deleteResponse.data) {
        toast.success("Address deleted!");
        setShowDelete(false);
      }
      fetchAddresses();
    } catch (error) {
      console.error("Error deleting address:", error);
      toast.error("Error deleting address. Please try again.");
    }
  };

  // function for handling value change of edit form inputs
  const editAddressChange = (e) => {
    const { name, value } = e.target;
    setExistingAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // function for posting and updating address info
  const handleAddressEdit = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const addressData = {
        addrId: existingAddress.addrId,
        name: existingAddress.name,
        houseName: existingAddress.houseName,
        street: existingAddress.street,
        landMark: existingAddress.landMark,
        city: existingAddress.city,
        state: existingAddress.state,
        pinCode: existingAddress.pinCode,
        country: existingAddress.country,
        countryCode: existingAddress.countryCode,
        mobile: existingAddress.mobile,
        addrType: existingAddress.addrType,
      };
      console.log(addressData);
      const savedAddressRes = await axios.post(
        `${BASE_URL}/users/updateAddress/${userId}`,
        addressData
      );

      handleEditClose();
      fetchAddresses();

      if (savedAddressRes && savedAddressRes.data) {
        toast.success("Your Address has been updated!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="profile-page">
      <Header />

      <div className="profile-form-container">
        <div className="user-info">
          <h3>Account</h3>
          <h5>User Information</h5>

          <div className="user-email-mob">
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={user.name}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Email"
              name="email"
              value={user.email}
              onChange={handleChange}
              readOnly
            />
            <input
              type="text"
              placeholder="Mobile No"
              name="mobile1"
              value={user.mobile1}
              onChange={handleChange}
            />

            <input
              type="text"
              placeholder="Alternate Mobile No."
              name="mobile2"
              value={user.mobile2}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="user-address">
          <h3>Shipping Address</h3>
          <div className="address-container">
            <div className="add-address-container">
              <address className="add-address">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CiCirclePlus
                    size={30}
                    style={{ color: "grey", cursor: "pointer" }}
                    onClick={handleShow}
                  />
                  <button className="add-address-btn" onClick={handleShow}>
                    Add Address
                  </button>
                </div>
              </address>
            </div>
            {addresses.map((address, index) => (
              <div key={index} className="address-item">
                <div>
                  <p style={{ fontWeight: "bold" }}>
                    {address.name && (
                      <React.Fragment>{address.name}</React.Fragment>
                    )}
                  </p>
                  <p>
                    {address.houseName}, {address.street}
                    <br />
                    {address.landMark}
                    <br />
                    {address.city}, {address.state}
                    <br />
                    {address.pinCode}
                    <br />
                    {address.countryCode} {address.mobile}
                    <br />
                    {address.country}
                  </p>
                  <button type="submit" onClick={() => handleEditShow(address)}>
                    Edit
                  </button>
                  <span> | </span>
                  <button
                    type="submit"
                    onClick={() => handleDeleteShow(address)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="save-button">
        <button type="submit" onClick={handleSave}>
          Save
        </button>
      </div>

      {/* -------------------------------Form for adding new address-----------------------------------*/}
      <Modal show={show} onHide={handleClose} className="add-address-form">
        <Modal.Header closeButton>
          <Modal.Title>Add New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body className="form-body">
          {addressErrors.allFields && (
            <span className="error" style={{ marginLeft: "50px" }}>
              {addressErrors.allFields}
            </span>
          )}
          <form
            className="delivery-address-form"
            style={{ paddingTop: "10px" }}
          >
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={newAddress.name}
              onChange={handleAddressChange}
              className={
                addressErrors.name ||
                (!newAddress.name && addressErrors.allFields)
                  ? "error-border"
                  : ""
              }
              required
            />
            <input
              type="text"
              placeholder="House No."
              name="houseName"
              value={newAddress.houseName}
              onChange={handleAddressChange}
              className={
                addressErrors.houseName ||
                (!newAddress.houseName && addressErrors.allFields)
                  ? "error-border"
                  : ""
              }
              required
            />
            <input
              type="text"
              placeholder="Street"
              name="street"
              value={newAddress.street}
              onChange={handleAddressChange}
              className={
                addressErrors.street ||
                (!newAddress.street && addressErrors.allFields)
                  ? "error-border"
                  : ""
              }
              required
            />

            <input
              type="text"
              placeholder="Apartment,suite,etc"
              name="landMark"
              value={newAddress.landMark}
              onChange={handleAddressChange}
              className={
                addressErrors.landMark ||
                (!newAddress.landMark && addressErrors.allFields)
                  ? "error-border"
                  : ""
              }
              required
            />
            {addressErrors.pinCode && (
              <span className="error">{addressErrors.pinCode}</span>
            )}
            <div id="add-info">
              <input
                type="text"
                placeholder="City"
                name="city"
                value={newAddress.city}
                onChange={handleAddressChange}
                className={
                  addressErrors.city ||
                  (!newAddress.city && addressErrors.allFields)
                    ? "error-border"
                    : ""
                }
                required
              />
              <input
                type="text"
                placeholder="State"
                name="state"
                value={newAddress.state}
                onChange={handleAddressChange}
                className={
                  addressErrors.state ||
                  (!newAddress.state && addressErrors.allFields)
                    ? "error-border"
                    : ""
                }
                required
              />
              <input
                type="text"
                placeholder="Pincode"
                name="pinCode"
                value={newAddress.pinCode}
                onChange={handleAddressChange}
                className={
                  addressErrors.pinCode ||
                  (!newAddress.pinCode && addressErrors.allFields)
                    ? "error-border"
                    : ""
                }
                required
              />
            </div>

            <input
              type="text"
              placeholder="Country"
              name="country"
              value={newAddress.country}
              onChange={handleAddressChange}
              className={
                addressErrors.country ||
                (!newAddress.country && addressErrors.allFields)
                  ? "error-border"
                  : ""
              }
              required
            />
            <input
              type="text"
              placeholder="+91 (Enter your country code)"
              name="countryCode"
              value={newAddress.countryCode}
              onChange={handleAddressChange}
              className={
                addressErrors.countryCode ||
                (!newAddress.countryCode && addressErrors.allFields)
                  ? "error-border"
                  : ""
              }
              required
            />
            {addressErrors.mobile && (
              <span className="error">{addressErrors.mobile}</span>
            )}
            <input
              type="text"
              placeholder="Mobile no."
              name="mobile"
              value={newAddress.mobile}
              onChange={handleAddressChange}
              className={
                addressErrors.mobile ||
                (!newAddress.mobile && addressErrors.allFields)
                  ? "error-border"
                  : ""
              }
              required
            />

            <div id="save-checkbox">
              <input type="checkbox" required />
              <label>Save this information for next time</label>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleAddAddress} id="save-changes-btn">
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
      {/* -------------------------------Form for editing existing address-----------------------------------*/}
      <Modal
        show={showEditForm}
        onHide={handleEditClose}
        className="add-address-form"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Address</Modal.Title>
        </Modal.Header>
        <Modal.Body className="form-body">
          <form className="delivery-address-form">
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={existingAddress.name}
              onChange={editAddressChange}
              required
            />
            <input
              type="text"
              placeholder="House No."
              name="houseName"
              value={existingAddress.houseName}
              onChange={editAddressChange}
              required
            />
            <input
              type="text"
              placeholder="Street"
              name="street"
              value={existingAddress.street}
              onChange={editAddressChange}
              required
            />

            <input
              type="text"
              placeholder="Apartment,suite,etc"
              name="landMark"
              value={existingAddress.landMark}
              onChange={editAddressChange}
              required
            />
            <div id="add-info">
              <input
                type="text"
                placeholder="City"
                name="city"
                value={existingAddress.city}
                onChange={editAddressChange}
                required
              />
              <input
                type="text"
                placeholder="State"
                name="state"
                value={existingAddress.state}
                onChange={editAddressChange}
                required
              />

              <input
                type="text"
                placeholder="Pincode"
                name="pinCode"
                value={existingAddress.pinCode}
                onChange={editAddressChange}
                required
              />
            </div>
            <input
              type="text"
              placeholder="Country"
              name="country"
              value={existingAddress.country}
              onChange={editAddressChange}
              required
            />
            <input
              type="text"
              placeholder="+91 (Enter your country code)"
              name="countryCode"
              value={existingAddress.countryCode}
              onChange={editAddressChange}
              required
            />

            <input
              type="text"
              placeholder="Mobile no."
              name="mobile"
              value={existingAddress.mobile}
              onChange={editAddressChange}
              required
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleAddressEdit} id="save-changes-btn">
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
      {/* -------------------------------Pop up for deleting existing address-----------------------------------*/}
      <Modal
        show={showDelete}
        onHide={handleDeleteClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addressToDelete && (
            <React.Fragment>
              <p>
                {/* Display the address details */}
                {addressToDelete.name}
                {addressToDelete.houseName}, {addressToDelete.street}
                <br />
                {addressToDelete.landMark}
                <br />
                {addressToDelete.city}, {addressToDelete.state}
                <br />
                {addressToDelete.pinCode}
                <br />
                {addressToDelete.country}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Please Note:</span>{" "}
                Deleting this address will not delete any pending orders being
                shipped to this address. To ensure uninterrupted fulfillment of
                future orders, please update any wishlists, subscribe and save
                settings and periodical subscriptions using this address.
              </p>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button onClick={handleDeleteAddress} id="dlt-add-yes-btn">
            Yes
          </button>
          <button onClick={handleDeleteClose} id="dlt-add-no-btn">
            No
          </button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </div>
  );
};
