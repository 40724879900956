import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import img1 from "../../images/Group 28.png";
import { IoStar } from "react-icons/io5";
import { FiMinus, FiPlus } from "react-icons/fi";
import "./CartPage.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../constant";
import { toast } from "react-toastify";
import { useCartContext } from "../../Context/CartContext";
import SliderHome from "../../components/SacredOfferingsSlider/SliderHome";
import { message } from "antd";

const CartPage = ({ selectedPack }) => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const stars = Array.from({ length: 5 });
  const [subtotal, setSubtotal] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const { fetchCartCount } = useCartContext();

  useEffect(() => {
    fetchCartItems();
  }, []);

  const userId = localStorage.getItem("userId");
  const relatedProductsRef = useRef(null);

  const fetchCartItems = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (userId) {
        const response = await axios.post(
          `${BASE_URL}/usercart/in-cart?userId=${userId}`
        );

        setCartItems(response.data);
        console.log(response.data);
        // Calculate subtotal
        let total = 0;
        response.data.forEach((item) => {
          total += item.productRate * item.orderQuantity;
        });
        setSubtotal(total);
        setDeliveryCharge(response.data.length > 0 ? 10 : 0);
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const handleProductRemove = async (productId, showNotification = true) => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.post(
        `${BASE_URL}/usercart/remove-product?userId=${userId}&productId=${productId}`
      );
      await fetchCartCount();
      console.log(response);

      if (showNotification) {
        toast.error("Product removed from cart");
      }

      // If deletion is successful, fetch the updated cart items
      fetchCartItems();
    } catch (error) {
      console.error("Error removing product from cart:", error);
      toast.error("Something went wrong! Try again later");
    }
  };

  const handleQuantityChange = async (productId, newQuantity) => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.post(
        `${BASE_URL}/usercart/update-quantity?userId=${userId}&productId=${productId}&orderQuantity=${newQuantity}`
      );
      console.log(response);

      // If quantity update is successful, fetch the updated cart items
      fetchCartItems();
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("Something went wrong! Try again later");
    }
  };

  const handleAddToWishlist = async (productId) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const wishlistResponse = await fetch(
          `${BASE_URL}/wishlist/add/${userId}`,

          {
            method: "POST",
            headers: {
              contentType: "application/json",
            },
            body: productId,
          }
        );
        await handleProductRemove(productId, false);
        console.log(productId);

        console.log(wishlistResponse.data);

        toast.success("Product added to wishlist");
      } catch (error) {
        console.error("Error adding product to wishlist:", error);
        toast.error("Something went wrong! Try again later");
      }
    } else {
      navigate("/login");
    }
  };

  const scrollToRelatedProducts = () => {
    relatedProductsRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleShare = (productId) => {
    if (navigator.clipboard) {
      const productDetailsUrl = `${window.location.origin}/productdetails/${productId}`;
      navigator.clipboard.writeText(productDetailsUrl);
      toast.success("Product link copied");
    }
  };

  return (
    <div className="cart-container">
      <Header />
      <div className="cart-products-container">
        {cartItems.length > 0 ? (
          <h4>{`${cartItems.length} items in you cart`}</h4>
        ) : (
          ""
        )}
        {cartItems.length > 0 ? (
          cartItems.map((item) => {
            return (
              <React.Fragment>
                <div key={item.productid} className="cart-product">
                  <img
                    src={item.productImage ? item.productImage : img1}
                    alt={item.productName}
                    loading="lazy"
                    onClick={() => navigate("/cart")}
                  />
                  <div className="cart-desc">
                    <div className="cart-row">
                      <h5>
                        {item.productName} -{" "}
                        {item.productDescription?.slice(0, 95)}...
                        <span>{item.description}</span>
                      </h5>
                      <div className="cart-product-price">
                        <div className="cart-product-rating">
                          <div>
                            {stars.map((_, index) => (
                              <IoStar size={22} key={index} />
                            ))}
                          </div>
                          <p>₹ {item.productRate}</p>
                        </div>
                      </div>
                    </div>
                    <div className="cart-buttons">
                      <div>
                        <p>Quantity</p>
                        <div className="cart-quantity-btn">
                          <button
                            type="button"
                            onClick={() =>
                              handleQuantityChange(
                                item.productid,
                                parseInt(item.orderQuantity, 10) - 1
                              )
                            }
                          >
                            <FiMinus />
                          </button>
                          {item.orderQuantity}
                          <button
                            type="button"
                            onClick={() =>
                              handleQuantityChange(
                                item.productid,
                                parseInt(item.orderQuantity, 10) + 1
                              )
                            }
                          >
                            <FiPlus />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="options">
                      <span onClick={() => handleAddToWishlist(item.productid)}>
                        Save for later
                      </span>
                      <span
                        onClick={() =>
                          handleProductRemove(item.productid, true)
                        }
                      >
                        Delete
                      </span>
                      <span onClick={scrollToRelatedProducts}>
                        See more like this
                      </span>
                      <span onClick={() => handleShare(item.productid)}>
                        Share
                      </span>
                    </div>
                    <button
                      id="cart-product-view"
                      onClick={() =>
                        navigate(`/productdetails/${item.productid}`)
                      }
                    >
                      View product
                    </button>
                  </div>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <div className="cart-products-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "50px 0",
              }}
            >
              <img
                src={
                  "https://images.vexels.com/media/users/3/132103/isolated/preview/2b512b5ece5d914e68950bfdbf73b481-shopping-cart-circle-icon-by-vexels.png"
                }
                width={"200px"}
              />
              <h3 style={{ fontSize: "30px" }}>Your Cart is Empty!</h3>
              <p style={{ fontSize: "20px" }}>
                Must add items before you proceed to checkout.
              </p>
              <button
                className="empty-cart-btn"
                type="submit"
                onClick={() => navigate("/shop")}
              >
                Add Products
              </button>
            </div>
          </div>
        )}
      </div>
      {userId ? (
        <React.Fragment>
          <div className="price-details-container">
            <div className="price-details">
              <div className="price-title">
                <p>Subtotal</p>
                <p>Shipping</p>
                <p>Total</p>
              </div>
              <div className="price-value">
                <p>Rs {subtotal.toFixed(2)}</p>
                <p>Rs {deliveryCharge.toFixed(2)}</p>
                <p>Rs {(subtotal + deliveryCharge).toFixed(2)}</p>
              </div>
            </div>
            <div className="price-detail-btn">
              <button
                type="submit"
                onClick={() => {
                  const userId = localStorage.getItem("userId");
                  if (userId && cartItems.length > 0) {
                    navigate("/checkout");
                  } else {
                    message.warning(
                      "Add Items in cart before proceed to checkout"
                    );
                  }
                }}
              >
                Proceed to Buy
              </button>
            </div>
          </div>
          <div ref={relatedProductsRef}>
            <SliderHome />
          </div>
        </React.Fragment>
      ) : (
        ""
      )}

      <Footer />
    </div>
  );
};

export default CartPage;
