import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import ShopPage from "./pages/ShopPage/ShopPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import { ContactPage } from "./pages/ContactPage/ContactPage";
import Login from "./pages/SignUp/Login";
import SignUp from "./pages/SignUp/SignUp";
import CartPage from "./pages/CartPage/CartPage";
import ScrollToTop from "./components/ScrollToTop";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Wishlist from "./pages/Wishlist/Wishlist";
import { ProfilePage } from "./pages/ProfilePage/ProfilePage";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import ForgotPassword from "./pages/SignUp/ForgotPassword";
import RecoverPassword from "./pages/SignUp/RecoverPassword";
import PaymentSuccessfull from "./pages/payment-status/PaymentSuccessfull";
import PaymentUnsuccessful from "./pages/payment-status/PaymentUnsuccessful";
import OrderHistory from "./pages/OrderHistory/OrderHistory";
import PrivacyPolicy from "./pages/Policies/PrivacyPolicy";
import TermsConditions from "./pages/Policies/Terms&Conditions";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/shop/:searchName" element={<ShopPage />} />
        <Route path="/productdetails/:productId" element={<ProductDetails />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/payment-successful" element={<PaymentSuccessfull />} />
        <Route path="/payment-unsuccessful" element={<PaymentUnsuccessful />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms&conditions" element={<TermsConditions />} />
      </Routes>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default App;
